export default {
  state: {
    invoices: [],
  },
  getters: {
    invoices: (state) => state.invoices,
  },
  mutations: {
    invoices(state, value) {
      state.invoices = value;
    },
    removeInvoiceById(state, id) {
      var invoice = state.invoices.filter((i) => i.id === id);
      var index = state.invoices.indexOf(invoice);
      state.invoices.splice(index, 1);
    },
    markInvoiceAsPaid(state, invoiceDetails) {
      var invoice = state.invoices.filter(
        (i) => i.id === invoiceDetails.invoiceId
      )[0];
      invoice.is_paid = true;
      invoice.was_paid_on = invoiceDetails.date;
    },
  },
};
