<template>
  <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        class="search-field"
        append-icon="mdi-magnify"
        label="Szukaj zamówienia"
        single-line
        hide-details
      />
      <v-spacer />
      <v-select
        v-model="selectedOrderStatus"
        class="mt-7"
        style="max-width:300px"
        outlined
        dense
        single-line
        :items="items"
        label="Status zamówienia"
      />
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="ordersIndexData()"
      :search="search"
      class="elevation-1"
      :no-data-text="this.noDataText"
      no-results-text="Brak wyników"
      :footer-props="{ itemsPerPageText: 'Wyników na stronę' }"
    >
      <template v-slot:item.pickOrder="{ item }">
        <router-link :to="{ name: 'Orderdisplay', params: { id: item.id } }"
          >Szczegóły</router-link
        >
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import axios from "axios";
import {
  convertUriToJson,
  formatDateTime,
  typeToPolish,
} from "@/utils/commonFunctions";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    interval: undefined,
    search: "",
    headers: [
      {
        text: "Nr zamówienia",
        value: "orderNumber",
        sortable: false,
      },
      { text: "Data złożenia", value: "orderedAt" },
      { text: "Instytucja", value: "institution", sortable: false },
      { text: "Ostatnia zmiana statusu", value: "lastStatusChange" },
      { text: "Nr statusu", value: "statusNumber" },
      { text: "Opis statusu", value: "statusDescription", sortable: false },
      { text: "Status nadany przez", value: "userEmail", sortable: false },
      { text: "Źródło", value: "type", sortable: false },
      { value: "pickOrder", sortable: false },
    ],
    items: [
      { text: "Niepotwierdzone", value: 10 },
      { text: "Proforma wystawiona", value: "20,30" },
      { text: "Potwierdzone", value: 40 },
      { text: "Skompletowane", value: 50 },
      { text: "Wysłane", value: 60 },
      { text: "Dostarczone", value: 70 },
      { text: "Wszystkie", value: "" },
    ],
  }),

  mounted() {
    axios
      .get("/api/v1/auth/validate_token", {
        email: this.email,
        password: this.password,
      })
      .catch((error) => {
        console.log(error);
      });
    clearInterval(this.interval);
    if (this.$cookies.get("order_status")) {
      this.fetchOrders();
      this.interval = setInterval(this.fetchOrders, 3600000);
    }
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },

  methods: {
    fetchOrders: function() {
      axios
        .get(`/api/v1/institution_orders${this.params}`)
        .then((response) => {
          this.$store.commit("orders", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    ordersIndexData() {
      return this.orders.map((e) => {
        return {
          id: e.id,
          orderNumber: e.order_number,
          orderedAt: formatDateTime(e.created_at),
          institution: e.institution_name,
          lastStatusChange: formatDateTime(e.last_status_change),
          statusNumber: e.status,
          statusDescription: e.status_description,
          userEmail: e.last_audit_user,
          type: typeToPolish(e.type),
        };
      });
    },

    openOrder(item) {
      this.$router.push({ name: "Orderdisplay", params: { id: item.id } });
    },
  },

  watch: {
    orderStatus: function() {
      clearInterval(this.interval);
      this.fetchOrders();
      this.interval = setInterval(this.fetchOrders, 3600000);
    },
  },

  computed: {
    params: function() {
      let parsedStatus = convertUriToJson(this.$cookies.get("order_status"));
      if (parsedStatus) {
        return `?status=${parsedStatus}`;
      } else {
        return "";
      }
    },

    noDataText: function() {
      if (!this.$cookies.get("order_status")) {
        return "Wybierz status";
      } else {
        return "Brak zamówień z tym statusem";
      }
    },

    orderStatus: function() {
      return this.$cookies.get("order_status");
    },

    selectedOrderStatus: {
      get: function() {
        return convertUriToJson(this.$cookies.get("order_status"));
      },
      set: function(status) {
        this.$cookies.set("order_status", JSON.stringify(status));
      },
    },

    ...mapGetters(["orders"]),
  },
};
</script>

<style scoped>
.search-field {
  max-width: 400px !important;
}
</style>
