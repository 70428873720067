<template>
  <div class="mb-5">
    <p><b>Numer zamówienia: </b>{{ order.order_number }}</p>
    <p><b>Data złożenia: </b>{{ formattedCreationDate }}</p>
    <p><b>Instytucja: </b>{{ order.institution_name }}</p>
    <p><b>Ostatnia zmiana statusu: </b>{{ formattedStatusChangeDate }}</p>
    <p>
      <b>Aktualny status: </b>{{ order.status }}, {{ order.status_description }}
    </p>
    <p><b>Status nadany przez: </b>{{ order.last_audit_user }}</p>
    <p><b>Adres dostawy: </b>{{ order.shipping_address }}</p>
    <p><b>Osoba kontaktowa: </b>{{ order.contact_person }}</p>
    <p><b>Zamówione opakowania: </b>{{ orderPackagesString }}</p>
    <p><b>Zamówione badania: </b>{{ testString }}</p>
    <v-divider class="mb-2" />
    <v-divider class="mb-5" />
    <p><b>Płatności</b></p>
    <v-data-table
      :headers="invoiceHeaders"
      :items="order.order_invoices"
      no-data-text="Nie wystawiono jeszcze żadnych faktur"
      disable-sort
      disable-pagination
      hide-default-footer
    >
      <template v-slot:item.isPaid="{ item }">
        {{ isPaidToPolish(item.is_paid) }}
      </template>
      <template v-slot:item.details="{ item }">
        <invoice-pdf v-bind:invoiceId="item.id" linkDescription="PDF" />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { formatDateTime, booleanToPolish } from "@/utils/commonFunctions";
import InvoicePdf from "@/components/InvoicePdf.vue";

export default {
  components: { InvoicePdf },

  data() {
    return {
      invoiceHeaders: [
        {
          text: "Nr faktury",
          align: "start",
          value: "ifirma_invoice_signature",
        },
        { text: "Opłacono?", value: "isPaid" },
        { text: "Szczegóły", value: "details" },
      ],
    };
  },

  computed: {
    formattedCreationDate() {
      return formatDateTime(this.order.created_at);
    },

    formattedStatusChangeDate() {
      return formatDateTime(this.order.last_status_change);
    },

    orderPackagesString() {
      return this.convertAmountsArrayToString(this.order.needed_packages);
    },

    testString() {
      return this.convertAmountsArrayToString(this.order.tests);
    },

    ...mapGetters({
      order: "order",
    }),
  },

  methods: {
    convertAmountsArrayToString(arr) {
      var stringsArray = [];
      for (const [k, v] of Object.entries(arr)) {
        stringsArray.push(`${v}x ${k}`);
      }
      return stringsArray.join(", ");
    },

    isPaidToPolish(isPaid) {
      return booleanToPolish(isPaid);
    },
  },
};
</script>
