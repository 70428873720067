<template>
  <div>
    <div v-if="order.status == 40">
      <package-picker ref="packagePicker" />
    </div>
    <div v-if="order.status == 50">
      <shipment-details-input ref="shippingDetailsInput" />
    </div>
    <v-btn
      v-if="order.status >= 40 && order.status < 70"
      class="action-btn"
      :disabled="disableAction.enabled"
      @click="showModal"
      >{{ action.description }}</v-btn
    >
    <p v-if="disableAction.enabled" class="my-2 red--text">
      {{ disableAction.description }}
    </p>
    <update-action-modal v-on:change-status="submit" ref="updateActionModal" />
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import UpdateActionModal from "@/components/modals/UpdateActionModal.vue";
import PackagePicker from "@/components/order_display/PackagePicker.vue";
import ShipmentDetailsInput from "@/components/order_display/ShipmentDetailsInput.vue";

export default {
  components: { UpdateActionModal, PackagePicker, ShipmentDetailsInput },

  mounted() {
    this.$store.commit(
      "shipmentCompleted",
      this.totalPaperAmount == this.order.needed_papers
    );
  },

  computed: {
    totalPaperAmount() {
      var total = 0;
      if (this.order.shipment) {
        this.order.shipment.packages.map(function(p) {
          total += p.paper_amount;
        });
      }
      return total;
    },

    action() {
      switch (this.order.status) {
        case 40:
          return { targetStatus: 50, description: "Wysyłka skompletowana" };
        case 50:
          return { targetStatus: 60, description: "Zamówienie wysłane" };
        case 60:
          return { targetStatus: 70, description: "Zamówienie dostarczone" };
      }
    },

    disableAction() {
      if (
        this.order.status >= 40 &&
        this.order.status < 60 &&
        !this.shipmentCompleted
      ) {
        return { enabled: true, description: "Niewłaściwa liczba bibuł" };
      } else if (
        this.order.status == 50 &&
        !this.$store.getters["shipmentDetailsFilled"]
      ) {
        return { enabled: true, description: "Uzupełnij dane wysyłki" };
      } else if (this.editPackages) {
        return {
          enabled: true,
          description: "Zatwierdź numery seryjne opakowań",
        };
      } else {
        return { enabled: false };
      }
    },

    updateParams() {
      var params = { institution_order: { status: this.action.targetStatus } };
      if (this.action.targetStatus === 60) {
        params.institution_order.shipment_attributes = {
          waybill_number: this.order.shipment.waybill_number,
          shipping_company_id: this.order.shipment.shipping_company_id,
        };
      }
      return params;
    },

    ...mapGetters({
      order: "order",
      shipmentCompleted: "shipmentCompleted",
      shipmentDetailsFilled: "shipmentDetailsFilled",
      editPackages: "editPackages",
    }),
  },

  methods: {
    submit: function() {
      axios
        .put(`/api/v1/institution_orders/${this.order.id}`, this.updateParams)
        .then(() => {
          this.$emit("reloadOrder");
          this.$store.commit("alert", {
            type: "success",
            message: "Status został pomyślnie zaktualizowany",
          });
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 403) {
            this.$emit("reloadOrder");
            this.$store.commit("alert", {
              type: "warning",
              message: error.response.data.message,
            });
          }
        });
    },

    showModal: function() {
      this.$refs.updateActionModal.show();
    },
  },
};
</script>
