export default {
  state: {
    orders: [],
    order: {},
    shipmentCompleted: false,
    shipmentDetailsFilled: false,
    editPackages: false,
  },
  getters: {
    orders: (state) => state.orders,
    order: (state) => state.order,
    shipmentCompleted: (state) => state.shipmentCompleted,
    shipmentDetailsFilled: (state) => state.shipmentDetailsFilled,
    editPackages: (state) => state.editPackages,
  },
  mutations: {
    orders(state, value) {
      state.orders = value;
    },
    order(state, value) {
      state.order = value;
    },
    addPackage(state, value) {
      state.order.shipment.packages.push(value);
    },
    removePackage(state, index) {
      state.order.shipment.packages.splice(index, 1);
    },
    addWaybillNumber(state, value) {
      state.order.shipment.waybill_number = value;
    },
    addShippingCompanyId(state, value) {
      state.order.shipment.shipping_company_id = value;
    },
    addShippingCompanyName(state, value) {
      state.order.shipment.shipping_company_name = value;
    },
    shipmentCompleted(state, value) {
      state.shipmentCompleted = value;
    },
    shipmentDetailsFilled(state, value) {
      state.shipmentDetailsFilled = value;
    },
    editPackages(state, value) {
      state.editPackages = value;
    },
  },
};
