<template>
  <v-card class="my-5">
    <v-card-title
      >Status {{ audit.current_status }}:
      {{ audit.current_status_description }}</v-card-title
    >
    <v-card-text class="text-body-1"
      ><span class="mr-5"><b>Data:</b> {{ formattedCreationDate }}</span>
      <br />
      <span><b>Użytkownik:</b> {{ audit.audit_user_email }}</span>
      <div v-if="this.audit.current_status == 50">
        <div v-if="!editPackages">
          <br />
          <b>Numery seryjne opakowań przypisanych do zamówienia:</b>
          <br />
          <p>{{ serialNumbers.join([(separator = "; ")]) }}</p>
        </div>
        <div v-else>
          <package-picker />
          <v-btn
            class="action-btn"
            :disabled="!shipmentCompleted"
            @click="$store.commit('editPackages', false)"
            >Zatwierdź</v-btn
          >
        </div>
        <v-btn
          v-if="!editPackages && order.status < 60 && order.status > 40"
          class="action-btn mt-3"
          @click="$store.commit('editPackages', true)"
          >Edytuj</v-btn
        >
      </div>
      <div v-if="this.audit.current_status == 60">
        <div class="mt-3">
          <span
            ><b>Przewoźnik:</b>
            {{ shipmentDetails.shipping_company_name }}</span
          >
          <br />
          <span
            ><b>Nr listu przewozowego:</b>
            {{ shipmentDetails.waybill_number }}</span
          >
        </div>
        <!--<v-btn
        v-if="!editPackages && order.status < 60 && order.status > 40"
        @click="$store.commit('editPackages', true)"
        >Edytuj</v-btn
      >-->
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import PackagePicker from "@/components/order_display/PackagePicker.vue";
import ShipmentDetailsInput from "@/components/order_display/ShipmentDetailsInput.vue";
import { formatDateTime } from "@/utils/commonFunctions";

export default {
  props: ["audit"],

  components: { PackagePicker, ShipmentDetailsInput },

  computed: {
    serialNumbers() {
      var serialNumbers = this.order.shipment.packages.map((p) => {
        return p.extended_serial_number;
      });
      return serialNumbers;
    },

    shipmentDetails() {
      return {
        waybill_number: this.order.shipment.waybill_number,
        shipping_company_name: this.order.shipment.shipping_company_name,
      };
    },

    formattedCreationDate() {
      return formatDateTime(this.audit.created_at);
    },

    ...mapGetters({
      order: "order",
      shipmentCompleted: "shipmentCompleted",
      editPackages: "editPackages",
    }),
  },
};
</script>
