<template>
  <div>
    <span v-for="(pack, index) of order.shipment.packages">
      <v-chip color="#B3E5FC" class="my-2 mr-3">
        <span class="mr-2">{{ pack.extended_serial_number }}</span>
        <v-icon @click="removePack(pack.id, index)"
          >mdi-trash-can-outline</v-icon
        >
      </v-chip>
    </span>
    <v-text-field
      v-model="newSerialNumber"
      placeholder="Wprowadź nr seryjny opakowania"
      @blur="clearErrors"
      @keyup.enter="addPack"
    ></v-text-field>
    <p v-if="this.errorText" class="red--text">{{ errorText }}</p>
    <p v-bind:class="{ 'red--text': !shipmentCompleted }">
      Liczba bibuł: {{ totalPaperAmount }} / {{ order.needed_papers }}
    </p>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";

export default {
  mixins: [validationMixin],

  mounted() {
    this.$store.commit(
      "shipmentCompleted",
      this.totalPaperAmount == this.order.needed_papers
    );
  },

  data: () => ({
    newSerialNumber: "",
    serialNumberNotFound: false,
    serialNumberTaken: false,
  }),

  computed: {
    totalPaperAmount() {
      var total = 0;
      if (this.order.shipment) {
        this.order.shipment.packages.map(function(p) {
          total += p.paper_amount;
        });
      }
      return total;
    },

    errorText() {
      if (this.serialNumberNotFound) {
        return "Opakowanie o tym numerze seryjnym nie istnieje";
      } else if (this.serialNumberTaken) {
        return "Opakowanie o tym numerze seryjnym zostało już przypisane";
      } else {
        null;
      }
    },

    ...mapGetters({
      order: "order",
      shipmentCompleted: "shipmentCompleted",
    }),
  },

  watch: {
    totalPaperAmount: function() {
      this.$store.commit(
        "shipmentCompleted",
        this.totalPaperAmount == this.order.needed_papers
      );
    },
  },

  //async mounted() {
  //  await axios
  //    .get(`/api/v1/institution_orders/${this.$store.getters.orderId}/packages`)
  //    .then((response) => {
  //      this.$store.commit("packages", response.data);
  //    })
  //    .catch((error) => {
  //      console.log(error);
  //    });
  //},

  methods: {
    addPack: function() {
      axios
        .get("/api/v1/package", {
          params: {
            serial_number: this.newSerialNumber,
          },
        })
        .then((response) => {
          if (response.data.shipment_id === null) {
            axios
              .put(`/api/v1/packages/${response.data.id}`, {
                shipment_id: this.order.shipment.id,
              })
              .then((response) => {
                this.$store.commit("addPackage", response.data);
                this.newSerialNumber = null;
                this.clearErrors();
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            this.clearErrors();
            this.serialNumberTaken = true;
            this.newSerialNumber = null;
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 404) {
            this.clearErrors();
            this.serialNumberNotFound = true;
            this.newSerialNumber = null;
          }
        });
    },

    removePack: function(id, index) {
      axios
        .put(`/api/v1/packages/${id}`, {
          shipment_id: null,
        })
        .then(() => {
          this.$store.commit("removePackage", index);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    clearErrors: function() {
      this.serialNumberNotFound = false;
      this.serialNumberTaken = false;
    },
  },
};
</script>
