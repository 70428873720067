<template>
  <v-dialog v-model="dialog" max-width="400px" persistent>
    <v-card>
      <v-card-title class="mb-5">Kiedy opłacono zamówienie?</v-card-title>
      <v-card-subtitle>
        <v-date-picker
          :max="new Date().toISOString()"
          :min="invoiceDetails.issueDate"
          v-model="date"
        ></v-date-picker>
      </v-card-subtitle>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="hide">
          Powróć
        </v-btn>
        <v-btn color="green darken-1" text @click="submit">
          Zatwierdź
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      invoiceDetails: {},
      date: new Date().toISOString().slice(0, 10),
    };
  },

  methods: {
    show(invoiceDetails) {
      this.dialog = true;
      this.invoiceDetails = invoiceDetails;
    },

    hide() {
      this.dialog = false;
    },

    submit: function() {
      this.invoiceDetails["date"] = this.date;
      this.$emit("mark-as-paid", this.invoiceDetails);
      this.hide();
    },
  },
};
</script>
