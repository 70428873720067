import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import VueAxios from "vue-axios";
import VueCookies from "vue-cookies-reactive";
import Vuelidate from "vuelidate";
import status from "http-status";
import { pick } from "lodash";
import { convertUriToJson } from "./utils/commonFunctions";

Vue.use(VueAxios, axios);
Vue.use(VueCookies);
Vue.use(Vuelidate);

Vue.config.productionTip = false;

Vue.$cookies.config("", "", "masdiag.pl");

axios.defaults.baseURL = "https://zamowieniabackend.masdiag.pl";

axios.interceptors.response.use(
  (response) => {
    if (response.headers["access-token"]) {
      intercept_auth_headers(response);
    }
    return response;
  },

  (error) => {
    if (
      router.currentRoute.name !== "Signin" &&
      error.response.status === status.UNAUTHORIZED
    ) {
      Vue.$cookies.remove("order_status");
      Vue.$cookies.remove("_orderpanel_session");
      Vue.$cookies.remove("order_status");
      router.push({ name: "Signin" });
      store.commit("alert", {
        type: "warning",
        message: "Sesja wygasła. Zaloguj się ponownie",
      });
    } else {
      if (error.response.headers["access-token"]) {
        intercept_auth_headers(error.response);
      }
    }
    return Promise.reject(error);
  }
);

axios.interceptors.request.use((config) => {
  let authHeader = store.getters["auth"];
  if (authHeader && Object.keys(authHeader).length !== 0) {
    config.headers = authHeader;
  }
  return config;
});

function intercept_auth_headers(response) {
  const authHeaders = pick(response.headers, [
    "access-token",
    "client",
    "expiry",
    "uid",
    "token-type",
  ]);
  store.commit("auth", authHeaders);
  const session = convertUriToJson(Vue.$cookies.get("_orderpanel_session"));
  if (session) {
    session["tokens"] = authHeaders;

    Vue.$cookies.set("_orderpanel_session", JSON.stringify(session), {
      expires: "8h",
    });
  }
}

//axios.interceptors.request.use(
//  (request) => {
//    if (request.url.includes("orders")) {
//      axios
//        .post("/api/v1/update_token")
//        .then((response) => {
//          axios.defaults.headers.common["Authorization"] =
//            "Bearer " + response.data.token;
//          localStorage.setItem("token", response.data.token);
//        })
//        .catch((error) => {
//          console.log(error);
//        });
//    }
//    return request;
//  },
//  (error) => {
//    return Promise.reject(error);
//  }
//);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
