<template>
  <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        class="search-field"
        append-icon="mdi-magnify"
        label="Szukaj faktury"
        single-line
        hide-details
      />
      <v-spacer />
      <v-select
        v-model="selectedPaymentStatus"
        class="mt-7"
        style="max-width:300px"
        outlined
        dense
        single-line
        :items="items"
        label="Status płatności"
      />
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="invoiceIndexData()"
      :search="search"
      class="elevation-1"
      :no-data-text="this.noDataText"
      no-results-text="Brak wyników"
      :footer-props="{ itemsPerPageText: 'Wyników na stronę' }"
    >
      <template v-slot:item.orderNumber="{ item }">
        <router-link
          :to="{ name: 'Orderdisplay', params: { id: item.orderId } }"
          >{{ item.orderNumber }}</router-link
        >
      </template>
      <template v-slot:item.isPaid="{ item }">
        {{ booleanToPolish(item.isPaid) }}
      </template>
      <template v-slot:item.dateOfPayment="{ item }">
        <span :class="colorOverduePayment(item)">{{ item.dateOfPayment }}</span>
      </template>
      <template v-slot:item.pdf="{ item }">
        <invoice-pdf v-bind:invoiceId="item.invoiceId" linkDescription="PDF" />
      </template>
      <template v-slot:item.markAsPaid="{ item }">
        <v-btn
          v-if="!item.isPaid"
          color="#98FB98"
          small
          @click="showModal(item)"
          >Oznacz jako opłaconą</v-btn
        >
      </template>
    </v-data-table>
    <mark-as-paid-modal v-on:mark-as-paid="submit" ref="markAsPaidModal" />
  </v-card>
</template>

<script>
import axios from "axios";
import {
  convertUriToJson,
  formatDate,
  booleanToPolish,
} from "@/utils/commonFunctions";
import { mapGetters } from "vuex";
import MarkAsPaidModal from "@/components/modals/MarkAsPaidModal.vue";
import InvoicePdf from "@/components/InvoicePdf.vue";

export default {
  components: { MarkAsPaidModal, InvoicePdf },

  data: () => ({
    interval: undefined,
    search: "",
    headers: [
      {
        text: "Nr faktury",
        value: "ifirmaSignature",
        sortable: false,
      },
      { text: "Nr zamówienia", value: "orderNumber", sortable: false },
      { text: "Instytucja", value: "institution", sortable: false },
      { text: "Data wystawienia", value: "issueDate" },
      { text: "Termin płatności", value: "dateOfPayment" },
      {
        text: "Opłacona?",
        value: "isPaid",
        sortable: false,
        filterable: false,
      },
      { text: "Data opłaty", value: "wasPaidOn" },
      {
        value: "pdf",
        sortable: false,
        filterable: false,
      },
      {
        value: "markAsPaid",
        sortable: false,
        filterable: false,
      },
    ],
    items: [
      { text: "Nieopłacone", value: 1 },
      { text: "Przekroczony termin płatności", value: 2 },
      { text: "Opłacone", value: 3 },
      { text: "Wszystkie", value: 4 },
    ],
  }),

  mounted() {
    axios
      .get("/api/v1/auth/validate_token", {
        email: this.email,
        password: this.password,
      })
      .catch((error) => {
        console.log(error);
      });
    clearInterval(this.interval);
    if (this.$cookies.get("payment_status")) {
      this.fetchInvoices();
      this.interval = setInterval(this.fetchInvoices, 3600000);
    }
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },

  methods: {
    fetchInvoices: function() {
      axios
        .get(`/api/v1/invoices${this.params}`)
        .then((response) => {
          this.$store.commit("invoices", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    updateParams: function(invoiceDetails) {
      var params = {
        invoice: {
          is_paid: true,
          was_paid_on: invoiceDetails.date,
        },
        institution_order: {},
      };
      if (invoiceDetails.orderStatus < 40) {
        if (invoiceDetails.orderNeedsShipment) {
          params.institution_order.status = 40;
        } else {
          params.institution_order.status = 70;
        }
      }
      return params;
    },

    colorOverduePayment(invoice) {
      if (Date.parse(invoice.dateOfPayment) < Date.now() && !invoice.isPaid) {
        return "red--text";
      } else {
        return " black--text";
      }
    },

    invoiceIndexData() {
      return this.invoices.map((e) => {
        return {
          ifirmaSignature: e.ifirma_invoice_signature,
          orderNumber: e.institution_order.order_number,
          institution: e.institution_name,
          issueDate: e.issue_date,
          dateOfPayment: e.date_of_payment,
          isPaid: e.is_paid,
          wasPaidOn: e.was_paid_on,
          invoiceId: e.id,
          orderId: e.institution_order.id,
          orderStatus: e.institution_order.status,
          orderNeedsShipment: e.institution_order["shipment_needed?"],
        };
      });
    },

    showModal: function(invoiceDetails) {
      this.$refs.markAsPaidModal.show(invoiceDetails);
    },

    booleanToPolish: function(boolean) {
      return booleanToPolish(boolean);
    },

    submit: function(invoiceDetails) {
      var params = this.updateParams(invoiceDetails);
      axios
        .put(`/api/v1/invoices/${invoiceDetails.invoiceId}`, {
          invoice: params.invoice,
        })
        .then(() => {
          if (
            params.institution_order &&
            Object.keys(params.institution_order).length !== 0
          ) {
            axios.put(`/api/v1/institution_orders/${invoiceDetails.orderId}`, {
              institution_order: params.institution_order,
            });
          }
          if (this.paymentStatus !== 4) {
            this.$store.commit("removeInvoiceById", invoiceDetails.invoiceId);
          } else {
            this.$store.commit("markInvoiceAsPaid", {
              invoiceId: invoiceDetails.invoiceId,
              date: invoiceDetails.date,
            });
          }
          this.$store.commit("alert", {
            type: "success",
            message: "Faktura została oznaczona jako opłacona",
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  watch: {
    selectedPaymentStatus: function() {
      clearInterval(this.interval);
      this.fetchInvoices();
      this.interval = setInterval(this.fetchInvoices, 3600000);
    },
  },

  computed: {
    params: function() {
      let parsedStatus = convertUriToJson(this.$cookies.get("payment_status"));
      if (parsedStatus) {
        return `?payment_status=${parsedStatus}`;
      } else {
        return "";
      }
    },

    noDataText: function() {
      if (!this.$cookies.get("payment_status")) {
        return "Wybierz status";
      } else {
        return "Brak faktur z tym statusem";
      }
    },

    paymentStatus: function() {
      return this.$cookies.get("payment_status");
    },

    selectedPaymentStatus: {
      get: function() {
        return convertUriToJson(this.$cookies.get("payment_status"));
      },
      set: function(status) {
        this.$cookies.set("payment_status", JSON.stringify(status));
      },
    },

    ...mapGetters(["invoices"]),
  },
};
</script>

<style scoped>
.search-field {
  max-width: 400px !important;
}
</style>
