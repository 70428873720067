var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-text-field',{staticClass:"search-field",attrs:{"append-icon":"mdi-magnify","label":"Szukaj faktury","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-select',{staticClass:"mt-7",staticStyle:{"max-width":"300px"},attrs:{"outlined":"","dense":"","single-line":"","items":_vm.items,"label":"Status płatności"},model:{value:(_vm.selectedPaymentStatus),callback:function ($$v) {_vm.selectedPaymentStatus=$$v},expression:"selectedPaymentStatus"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.invoiceIndexData(),"search":_vm.search,"no-data-text":this.noDataText,"no-results-text":"Brak wyników","footer-props":{ itemsPerPageText: 'Wyników na stronę' }},scopedSlots:_vm._u([{key:"item.orderNumber",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'Orderdisplay', params: { id: item.orderId } }}},[_vm._v(_vm._s(item.orderNumber))])]}},{key:"item.isPaid",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.booleanToPolish(item.isPaid))+" ")]}},{key:"item.dateOfPayment",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.colorOverduePayment(item)},[_vm._v(_vm._s(item.dateOfPayment))])]}},{key:"item.pdf",fn:function(ref){
var item = ref.item;
return [_c('invoice-pdf',{attrs:{"invoiceId":item.invoiceId,"linkDescription":"PDF"}})]}},{key:"item.markAsPaid",fn:function(ref){
var item = ref.item;
return [(!item.isPaid)?_c('v-btn',{attrs:{"color":"#98FB98","small":""},on:{"click":function($event){return _vm.showModal(item)}}},[_vm._v("Oznacz jako opłaconą")]):_vm._e()]}}])}),_c('mark-as-paid-modal',{ref:"markAsPaidModal",on:{"mark-as-paid":_vm.submit}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }