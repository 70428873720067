<template>
  <v-dialog v-model="dialog" persistent>
    <v-card>
      <v-card-title class="mb-5">Dodaj nowego przewoźnika</v-card-title>
      <v-card-text>
        <v-form class="mb-5">
          <v-text-field v-model="companyName" placeholder="Nazwa firmy" />
          <p v-if="errors.name" class="red--text">
            {{ `${errors.name}` }}
          </p>
          <v-text-field
            v-model="contractSignature"
            placeholder="Nr umowy (opcjonalnie)"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="addShippingCompany">
          Dodaj
        </v-btn>
        <v-btn color="green darken-1" text @click="hide">
          Anuluj
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  data() {
    return {
      dialog: false,
      companyName: "",
      contractSignature: "",
      errors: [],
    };
  },

  computed: {
    ...mapGetters({ order: "order" }),
  },

  methods: {
    show() {
      this.dialog = true;
    },

    hide() {
      this.dialog = false;
    },

    addShippingCompany: function() {
      axios
        .post("api/v1/shipping_companies", {
          name: this.companyName,
          contract_signature: this.contractSginature,
        })
        .then((response) => {
          this.hide();
          this.$emit("add-company", response.data);
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
  },
};
</script>
