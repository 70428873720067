<template>
  <v-card class="pa-3 mb-3">
    <v-row class="ml-1">
      <v-select
        return-object
        v-model="selectedCompany"
        autocomplete="off"
        item-value="id"
        label="Wybierz przewoźnika"
        :items="shippingCompanies"
      >
        <template v-slot:selection="data">
          {{ data.item.name }}
        </template>
        <template v-slot:item="data">
          {{ data.item.name }}
        </template>
      </v-select>
      <v-spacer />
      <v-btn class="action-btn mt-3 mr-3" small @click="showModal"
        >Dodaj przewoźnika</v-btn
      >
    </v-row>
    <v-row class="ml-1 mb-2">
      <v-text-field
        v-model="waybillNumber"
        autocomplete="off"
        placeholder="Wprowadź nr listu przewozowego lub dokumentu magazynowego"
      ></v-text-field>
    </v-row>
    <v-row class="ml-1 mb-2">
      <v-spacer />
      <v-btn class="action-btn mt-3 mr-3" small @click="updateShipmentDetails"
        >Zapamiętaj szczegóły</v-btn
      >
    </v-row>
    <!--<v-btn
      class="action-btn"
      @click="addShipmentDetails"
      :disabled="!this.selectedCompany || !this.waybillNumber"
      >Zatwierdź</v-btn
    >
    <v-btn class="action-btn" @click="showModal">Dodaj przewoźnika</v-btn>
    <p v-if="!this.selectedCompany || !this.waybillNumber" class="red--text">
      Uzupełnij dane
    </p>-->
    <shipping-company-creation-modal
      ref="shippingCompanyCreationModal"
      v-on:add-company="addCompanyToList"
    />
  </v-card>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import ShippingCompanyCreationModal from "@/components/modals/ShippingCompanyCreationModal.vue";

export default {
  components: { ShippingCompanyCreationModal },

  data: () => ({
    waybillNumber: null,
    selectedCompany: null,
    shippingCompanies: [],
  }),

  mounted() {
    this.getShippingCompanies();
    this.selectedCompany = this.order.shipment.shipping_company_id;
    this.waybillNumber = this.order.shipment.waybill_number;
  },

  watch: {
    waybillNumber: function() {
      this.$store.commit("addWaybillNumber", this.waybillNumber);
      if (this.waybillNumber) {
        this.$store.commit(
          "shipmentDetailsFilled",
          this.waybillNumber && this.selectedCompany
        );
      } else {
        this.$store.commit("shipmentDetailsFilled", false);
      }
    },

    selectedCompany: function() {
      if (this.selectedCompany) {
        this.$store.commit("addShippingCompanyId", this.selectedCompany.id);
        this.$store.commit(
          "shipmentDetailsFilled",
          this.waybillNumber && this.selectedCompany
        );
      }
    },
  },

  computed: {
    ...mapGetters({ order: "order" }),
  },

  methods: {
    getShippingCompanies: function() {
      axios
        .get("api/v1/shipping_companies")
        .then((response) => (this.shippingCompanies = response.data))
        .catch((error) => {
          console.log(error);
        });
    },

    //addShipmentDetails: function() {
    //  axios
    //    .put(`/api/v1/shipments/${this.order.shipment.id}`, {
    //      shipping_company_id: this.selectedCompany.id,
    //      waybill_number: this.waybillNumber,
    //    })
    //    .then((response) => {
    //      this.$store.commit("addWaybillNumber", response.data.waybill_number);
    //      this.$store.commit(
    //        "addShippingCompanyId",
    //        response.data.shipping_company_id
    //      );
    //      this.$store.commit(
    //        "addShippingCompanyName",
    //        response.data.shipping_company_name
    //      );
    //      this.$store.commit("editShipmentDetails", false);
    //    })
    //    .catch((error) => {
    //      console.log(error);
    //    });
    //},

    showModal: function() {
      this.$refs.shippingCompanyCreationModal.show();
    },

    addCompanyToList: function(company) {
      this.shippingCompanies.push(company);
      this.selectedCompany = company;
    },

    updateShipmentDetails: function() {
      axios
        .patch(`api/v1/shipments/${this.order.shipment.id}`, {
          waybill_number: this.waybillNumber,
          shipping_company_id: this.selectedCompany.id,
        })
        .then()
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
