<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <v-card>
      <v-card-title class="mb-5"
        >Przechodzisz do kolejnego etapu zamówienia</v-card-title
      >
      <v-card-subtitle
        >Czy na pewno chcesz przeprowadzić tę akcję?</v-card-subtitle
      >
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="submit">
          Tak
        </v-btn>
        <v-btn color="green darken-1" text @click="hide">
          Nie
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      dialog: false,
    };
  },

  computed: {
    ...mapGetters({ order: "order" }),
  },

  methods: {
    show() {
      this.dialog = true;
    },

    hide() {
      this.dialog = false;
    },

    submit: function() {
      this.$emit("change-status");
      this.hide();
    },
  },
};
</script>
