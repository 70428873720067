<template>
  <div>
    <page-not-found v-if="error404"></page-not-found>
    <div v-if="dataReady">
      <v-row class="mt-5">
        <v-col v-if="order.status < 70 && order.status > 30">
          <div class="pa-4" style="height:100%;">
            <status-update-action @reloadOrder="fetchOrder(order.id)" />
          </div>
        </v-col>
        <v-col>
          <v-card class="pa-4" style="height:100%;">
            <order-details />
            <div class="d-flex flex-row-reverse">
              <v-btn to="/orders">Powrót do zamówień</v-btn>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-divider class="my-10" />
      <h2>Zmiany statusu:</h2>
      <audit
        v-for="audit in auditsFromNewestToOldest"
        v-bind:key="audit.id"
        v-bind:audit="audit"
      ></audit>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import PageNotFound from "@/components/PageNotFound.vue";
import Audit from "@/components/order_display/Audit.vue";
import StatusUpdateAction from "@/components/order_display/StatusUpdateAction.vue";
import OrderDetails from "@/components/order_display/OrderDetails.vue";

export default {
  props: ["id"],

  components: {
    "page-not-found": PageNotFound,
    audit: Audit,
    "status-update-action": StatusUpdateAction,
    "order-details": OrderDetails,
  },

  data: () => ({
    error404: null,
    dataReady: false,
  }),

  async created() {
    await this.fetchOrder(this._props.id);
  },

  computed: {
    totalPaperAmount() {
      var total = 0;
      this.order.shipment.packages.map(function(p) {
        total += p.attributes.paper_amount;
      });
      return total;
    },

    auditsFromNewestToOldest() {
      return this.order.audits.sort(function(a, b) {
        return new Date(b.created_at) - new Date(a.created_at);
      });
    },

    ...mapGetters({
      order: "order",
    }),
  },

  methods: {
    fetchOrder: function(id) {
      return axios
        .get(`/api/v1/institution_orders/${id}`)
        .then(
          function(response) {
            this.$store.commit("order", response.data);
            this.dataReady = true;
          }.bind(this)
        )
        .catch((error) => {
          if (error.response.status === 404) {
            this.error404 = true;
          }
        });
    },
  },
};
</script>
