<template>
  <v-form @keyup.native.enter="submit">
    <h2 class="mt-3 mb-7" align="left">
      Zaloguj się
    </h2>
    <v-text-field
      v-model.trim="email"
      autocomplete="username"
      outlined
      label="Email"
    ></v-text-field>
    <v-text-field
      v-model="password"
      outlined
      autocomplete="new-password"
      :type="show ? 'text' : 'password'"
      label="Hasło"
      ><template v-slot:append>
        <v-btn
          small
          plain
          text
          class="eye-btn"
          :ripple="false"
          @click="show = !show"
          tabindex="-1"
        >
          <v-icon v-if="show">mdi-eye</v-icon>
          <v-icon v-if="!show">mdi-eye-off</v-icon>
        </v-btn></template
      ></v-text-field
    >
    <p>
      <router-link :to="{ name: 'Passwordresetrequest' }"
        >Resetuj hasło</router-link
      >
    </p>
    <v-btn class="action-btn mr-7 mb-7" x-large color="primary" @click="submit">
      Zatwierdź
    </v-btn>
  </v-form>
</template>

<script>
import axios from "axios";
import { pick } from "lodash";

export default {
  data: () => ({
    email: "",
    password: "",
    show: false,
    failedLogin: false,
    unconfirmedAccount: false,
  }),

  methods: {
    submit: function() {
      axios
        .post("/api/v1/auth/sign_in", {
          email: this.email,
          password: this.password,
        })
        .then((response) => {
          const authHeaders = pick(response.headers, [
            "access-token",
            "client",
            "expiry",
            "uid",
            "token-type",
          ]);
          this.$store.commit("auth", authHeaders);
          this.$store.commit("user", response.data.data);
          const contents = {
            tokens: authHeaders,
            user: response.data.data,
          };
          this.$cookies.set(
            "_orderpanel_session",
            JSON.stringify(contents),
            "8h"
          );
          this.$router.push({ path: "/orders" });
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$store.commit("alert", {
              type: "error",
              message: "Niewłaściwe dane logowania",
            });
          } else {
            this.$store.commit("alert", {
              type: "error",
              message:
                "Wystąpił błąd podczas logowania, skontaktuj się z działem IT",
            });
          }
        });
    },
  },
};
</script>
