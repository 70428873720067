<template>
  <div>
    <a
      :href="invoicePdfLink(this.invoiceId)"
      @click="getCurrentCookies"
      target="_blank"
      >{{ linkDescription }}
    </a>
  </div>
</template>

<script>
import { convertUriToJson } from "@/utils/commonFunctions";

export default {
  props: ["invoiceId", "linkDescription"],

  methods: {
    invoicePdfLink(id) {
      return `https://zamowieniabackend.masdiag.pl/api/v1/invoice_pdf/${id}`;
    },

    sleep: function(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },

    async getCurrentCookies() {
      await this.sleep(500);
      this.$cookies.refresh();

      const session = convertUriToJson(
        this.$cookies.get("_orderpanel_session")
      );
      this.$store.commit("auth", session.tokens);
    },
  },
};
</script>
