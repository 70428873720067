<template>
  <v-app>
    <v-card>
      <v-toolbar flat color="primary" dark>
        <v-toolbar-title>Obsługa zamówień Masdiag</v-toolbar-title>
        <v-spacer />
        <v-chip v-if="user" color="blue">
          <span
            >Zalogowano jako: <strong>{{ user.email }}</strong>
          </span>
        </v-chip>
        <v-spacer />
        <v-btn v-if="user" color="transparent" elevation="0" @click="logout">
          Wyloguj się
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-if="user" dense centered>
            <v-tab to="/orders">Zamówienia</v-tab>
            <v-tab to="/invoices">Faktury</v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
    </v-card>

    <v-main class="mx-10">
      <v-alert v-if="this.alert" class="my-3" dismissible :type="alert.type"
        >{{ alert.message }}
        <template v-slot:close="{ toggle }">
          <v-icon @click="disableAlert()">
            mdi-minus-circle
          </v-icon>
        </template></v-alert
      >
      <router-view class="mt-3" />
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";
import { convertUriToJson } from "./utils/commonFunctions";
import { mapGetters } from "vuex";

export default {
  name: "App",

  beforeCreate() {
    const session = convertUriToJson(this.$cookies.get("_orderpanel_session"));
    if (session) {
      this.$store.commit("user", session.user);
      this.$store.commit("auth", session.tokens);
    }
  },

  computed: {
    user: function() {
      return this.$store.getters["user"];
    },

    ...mapGetters({
      alert: "alert",
    }),
  },

  methods: {
    logout: async function() {
      await this.deleteTokensRequest();
      this.$cookies.remove("_orderpanel_session");
      this.$store.commit("user", null);
      this.$store.commit("auth", {});
      this.$store.commit("orders", []);
      this.$cookies.remove("order_status");
      this.$router.push({ name: "Signin" });
    },

    deleteTokensRequest: function() {
      axios.delete("/api/v1/auth/sign_out").catch((error) => {
        console.log(error);
      });
    },

    disableAlert: function() {
      this.$store.commit("alert", null);
    },
  },
};
</script>

<style>
.action-btn {
  background-color: #4d94ff !important;
  color: white !important;
}

.v-text-field {
  max-width: 500px !important;
}

.v-dialog {
  max-width: 600px;
}
</style>
