<template>
  <v-form v-on:submit.prevent @keyup.native.enter="submit">
    <h2 class="mt-3 mb-7" align="left">
      Wyślij link do resetowania hasła
    </h2>
    <v-text-field v-model.trim="email" outlined label="Email"></v-text-field>

    <p></p>
    <v-btn class="action-btn mr-7 mb-7" x-large color="primary" @click="submit">
      Wyślij
    </v-btn>
    <p>
      <router-link :to="{ name: 'Signin' }"
        >Wróć do strony logowania</router-link
      >
    </p>
  </v-form>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    email: "",
  }),

  methods: {
    submit: function() {
      axios
        .post("/api/v1/auth/password", {
          email: this.email,
          redirect_url: "https://zamowienia.masdiag.pl/#/new_password",
        })
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.$router.push({ name: "Signin" });
          this.$store.commit("alert", {
            type: "success",
            message: "Na podany adres został wysłany link do resetowania hasła",
          });
        });
    },
  },
};
</script>
