import Vue from "vue";
import VueRouter from "vue-router";
import Signin from "@/components/SignIn.vue";
import Orderindex from "@/components/OrderIndex.vue";
import Invoiceindex from "@/components/InvoiceIndex.vue";
import Orderdisplay from "@/components/order_display/OrderDisplay.vue";
import Passwordresetrequest from "@/components/PasswordResetRequest.vue";
import Newpasswordform from "@/components/NewPasswordForm.vue";
import PageNotFound from "@/components/PageNotFound.vue";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Signin",
    component: Signin,
  },
  {
    path: "/orders",
    name: "Orderindex",
    component: Orderindex,
  },
  {
    path: "/orders/:id",
    name: "Orderdisplay",
    component: Orderdisplay,
    props: (route) => ({ id: route.params.id }),
  },
  {
    path: "/invoices",
    name: "Invoiceindex",
    component: Invoiceindex,
  },
  {
    path: "/send_password_reset",
    name: "Passwordresetrequest",
    component: Passwordresetrequest,
  },
  {
    path: "/new_password",
    name: "Newpasswordform",
    component: Newpasswordform,
  },
  {
    path: "*",
    component: PageNotFound,
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  store.commit("alert", null);
  if (
    ["Signin", "Newpasswordform", "Passwordresetrequest"].includes(to.name) &&
    Vue.$cookies.get("_orderpanel_session")
  ) {
    next({ name: "Orderindex" });
  } else {
    next();
  }
});

export default router;
