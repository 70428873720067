import moment from "moment";

export const convertUriToJson = (uri) => {
  if (uri) {
    let decodedURI = decodeURIComponent(uri);
    return JSON.parse(decodedURI);
  } else {
    return null;
  }
};

export const sliceDateTime = (dateTime) => {
  if (dateTime) {
    return dateTime.slice(0, 10);
  } else {
    return null;
  }
};

export const formatDateTime = (dateTime) => {
  if (dateTime) {
    return moment(dateTime).format("YYYY-MM-DD, HH:mm:ss");
  } else {
    return null;
  }
};

export const formatDate = (dateTime) => {
  if (dateTime) {
    return moment(dateTime).format("YYYY-MM-DD");
  } else {
    return null;
  }
};

export const auditUserEmail = (audit) => {
  if (audit.user) {
    if (audit.user_type === "Contractor") {
      return `${audit.user.email} (KLIENT)`;
    } else {
      return audit.user.email;
    }
  }
};

export const booleanToPolish = (boolean) => {
  if (boolean == true) {
    return "Tak";
  } else if (boolean == false) {
    return "Nie";
  }
};

export const typeToPolish = (type) => {
  switch (type) {
    case "OnlineOrder":
      return "Platforma b2b";
    default:
      return type;
  }
};
