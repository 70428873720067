<template>
  <v-form>
    <h2 class="mt-3 mb-7" align="left">
      Ustaw nowe hasło
    </h2>
    <v-text-field
      v-model="password"
      outlined
      autocomplete="off"
      :type="show ? 'text' : 'password'"
      label="Hasło"
      ><template v-slot:append>
        <v-btn
          small
          plain
          text
          class="eye-btn"
          :ripple="false"
          @click="show = !show"
          tabindex="-1"
        >
          <v-icon v-if="show">mdi-eye</v-icon>
          <v-icon v-if="!show">mdi-eye-off</v-icon>
        </v-btn></template
      ></v-text-field
    >
    <v-text-field
      v-model="passwordConfirmation"
      outlined
      autocomplete="off"
      :type="showPasswordConfirmation ? 'text' : 'password'"
      label="Potwierdzenie hasła"
      ><template v-slot:append>
        <v-btn
          small
          plain
          text
          class="eye-btn"
          :ripple="false"
          @click="showPasswordConfirmation = !showPasswordConfirmation"
          tabindex="-1"
        >
          <v-icon v-if="showPasswordConfirmation">mdi-eye</v-icon>
          <v-icon v-if="!showPasswordConfirmation">mdi-eye-off</v-icon>
        </v-btn></template
      ></v-text-field
    >
    <v-btn class="action-btn mr-7 mb-7" x-large color="primary" @click="submit">
      Zatwierdź
    </v-btn>
  </v-form>
</template>

<script>
import axios from "axios";
import { pick } from "lodash";

export default {
  data: () => ({
    password: "",
    passwordConfirmation: "",
    show: false,
    showPasswordConfirmation: false,
  }),

  methods: {
    submit: function() {
      let config = { headers: this.$route.query };
      axios
        .put(
          "/api/v1/auth/password",
          {
            password: this.password,
            password_confirmation: this.passwordConfirmation,
          },
          config
        )
        .then(() => {
          this.$router.push({ name: "Signin" });
          this.$store.commit("alert", {
            type: "success",
            message: "Hasło zmienione. Zaloguj się ponownie.",
          });
        })
        .catch((error) => {
          console.log(error.response);
          if (error.response.status === 422) {
            this.$store.commit("alert", {
              type: "error",
              message:
                "Hasło musi zawierać co najmniej 8 znaków i być zgodne z potwierdzeniem.",
            });
          }
        });
    },
  },
};
</script>
