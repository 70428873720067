import Vue from "vue";
import Vuex, { Store } from "vuex";
import orderPanel from "@/store/modules/order-index";
import invoicePanel from "@/store/modules/invoice-index";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    auth: {},
    alert: null,
  },

  getters: {
    user: (state) => state.user,
    auth: (state) => state.auth,
    alert: (state) => state.alert,
  },

  mutations: {
    user(state, value) {
      state.user = value;
    },
    auth(state, value) {
      state.auth = value;
    },
    alert(state, value) {
      state.alert = value;
    },
  },

  modules: {
    orderPanel: orderPanel,
    invoicePanel: invoicePanel,
  },
});
